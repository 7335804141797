import Vue from 'vue';

import BootstrapVue from 'bootstrap-vue';
import '@babel/polyfill';
import 'mutationobserver-shim';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);
